<template>
  <nav
    class="h-nav-desktop fixed z-top w-full bg-white transition-shadow"
    :class="lastYPostition < 10 ? 'shadow-none' : 'shadow-md'"
    aria-label="main"
  >
    <Container indent="container-default">
      <!-- META NAV -->
      <ul
        class="relative mr-2 flex justify-end space-x-4 transition-all"
        :class="isExpanded ? 'h-9 ' : 'overflow-hidden h-0 mb-4'"
      >
        <li
          v-for="(el, index) in meta.tree"
          :key="index"
          class="flex items-center pr-px text-2xs font-medium hover:text-blue-500 green:hover:text-green-500 pink:hover:text-pink-500"
        >
          <NuxtLink
            v-if="el.page.url"
            :to="el.page.url"
            class="focus-default -m-1 flex items-center p-1 leading-normal"
          >
            <IconHeart v-if="el.page.hasHeart" class="mr-1.5 w-3" />
            {{ el.page.title }}
          </NuxtLink>
        </li>
        <li v-if="isInLivePreview" class="flex items-center">
          <NavigationSimpleLang />
        </li>
      </ul>

      <!-- DIVIDER -->
      <div
        :class="isExpanded ? 'h-px mb-3 mt-px' : 'h-0 '"
        class="ml-[-30vw] w-[200vw] bg-stone-200 transition-all"
      />
      <!-- LOGO -->
      <div class="relative flex pb-4" @mouseleave="open = false">
        <div
          :ref="
            (el) => {
              logo = el
            }
          "
          class="mr-6 flex w-52 min-w-[6rem] shrink-0 flex-col justify-center pt-1"
        >
          <NuxtLink
            to="/"
            class="focus-default -m-2 -mr-4 w-full p-2"
            aria-label="Link zur Startseite"
          >
            <IconLogo class="w-full object-contain" />
          </NuxtLink>
        </div>

        <!-- NAV ELEMENTS -->
        <ul class="flex items-center">
          <li
            v-for="(level1, index) in nav.tree.slice(0, -1)"
            :key="index"
            :ref="
              (el) => {
                navElements[index] = el
              }
            "
            class="group text-sm font-medium lg:text-base"
            :class="`${level1?.page?.color_theme?.value || 'blue'}-el`"
            @mouseenter="
              ;(open = true),
                (dropdown = level1),
                (hoverIndex = index),
                (enableDropdownTab = false)
            "
          >
            <Component
              :is="buttonOrNuxtLink(level1)"
              :id="`${index == 0 ? 'jump-navigation' : ''}`"
              class="focus-default button:cursor-default block min-w-max px-4 py-2 blue-el:hover:text-blue-500 green-el:hover:text-green-500 pink-el:hover:text-pink-500 lg:px-6"
              :class="{
                'blue-el:text-blue-500 green-el:text-green-500 pink-el:text-pink-500':
                  isActive(level1),
              }"
              :to="level1.page.url"
              @focus="dropdown = level1"
              @keyup.space="
                buttonOrNuxtLink(level1) !== 'button'
                  ? ((enableDropdownTab = false),
                    (open = false),
                    (hoverIndex = 0))
                  : ((enableDropdownTab = true),
                    (open = true),
                    (hoverIndex = index))
              "
              @keyup.esc=";(enableDropdownTab = false), (open = false)"
            >
              {{ level1.page.title }}
            </Component>
          </li>
        </ul>

        <!-- Spacer element -->
        <div class="flex-1" />
        <!-- KONTAKT BTN -->
        <NuxtLink
          :class="
            isExpanded
              ? 'opacity-0 pointer-events-none !select-none     '
              : 'opacity-100'
          "
          :tabindex="isExpanded ? '-1' : ''"
          :to="nav.tree.slice(-1)[0]?.page?.url"
          class="focus-default pointer-events-none hidden rounded-full bg-blue-500 px-6 py-2 text-2xs font-medium text-white transition-opacity hover:bg-blue-600 xl:pointer-events-auto xl:flex"
        >
          <IconFlag class="mr-2 w-4" />
          {{ nav.tree.slice(-1)[0]?.page?.title }}
        </NuxtLink>

        <!-- Hover element -->
        <NavigationHoverUnderline
          :hoverindex="hoverIndex"
          :nav="nav"
          :logo="logo"
          :elements="navElements"
          :open="open"
          :activeindex="activeIndex"
        />
      </div>
    </Container>
    <NavigationDesktopDropdown
      :dropdown="dropdown"
      :usesfocus="enableDropdownTab"
      :open="open"
      @mouseenter="open = true"
      @close-dropdown="open = false"
    />
  </nav>
</template>

<script setup>
import { useWindowScroll } from '@vueuse/core'

const props = defineProps({
  nav: { type: Object, required: true },
  meta: { type: Object, required: true },
})

const route = useRoute()

const isInLivePreview = !!route.query['live-preview']

const isExpanded = ref(true)
let lastYPostition = ref(0)
// toggles dropdown
const open = ref(false)
// contains dropdow Data and updates after hover change
const dropdown = ref({})
// toggles if links in dropdown are focusable
const enableDropdownTab = ref(false)
// html ref to all navElements
const navElements = ref([])
const logo = ref(null)
// animated hover underline
const hoverIndex = ref(0)
const activeIndex = ref(0)

useEventListener('scroll', () => {
  const { y } = useWindowScroll()
  let direction = y.value < lastYPostition.value ? 'up' : 'down'
  isExpanded.value = y.value < 60 || direction == 'up'
  lastYPostition.value = y.value
})

// close dropdown on route change
watch(
  () => route.fullPath,
  () => {
    open.value = false
    //set active element for underline
    activeIndex.value = getActiveIndex()
  }
)

// check if navItem is a button or a nuxtLink based on blueprint
const buttonOrNuxtLink = (navItem) => {
  if (navItem.page.blueprint === 'content_page') {
    return resolveComponent('NuxtLink')
  }

  return 'button'
}

//check if level 1 is active
const isActive = (navItem) => {
  return navItem.page?.url?.split('/')[1] === route.params?.uri[0]
}

function getActiveIndex() {
  let active = false
  //finde active index
  props.nav.tree.forEach((level1, index) => {
    if (isActive(level1)) {
      active = index
    }
  })
  return active
}
activeIndex.value = getActiveIndex()
</script>

<style scoped>
button.button\:cursor-default {
  @apply cursor-default;
}

.router-link-active {
  @apply text-blue-500;
}
</style>
